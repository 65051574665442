<template>
    <main>
        <HeaderTab title="Rapprochement facture" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                    <label>Rechercher une adresse e-mail <font-awesome-icon v-if="spinner" :icon="['fal', 'circle-notch']" spin/></label>
                    <b-form-input v-model="email" :placeholder="$t('compte.email')" type="email"></b-form-input>

                    <div 
                        v-for="(licence, key) in licences"
                        :key="'licence_'+key"
                    >
                        <br><hr><br>
                        <div class="row" >
                            <label class="col">Licence {{ key }}</label>
                        </div>

                        <div class="row" >
                            <div class="col">
                                <select v-model="annee_selected">
                                    <option
                                        v-for="v in annee"
                                        :key="v"
                                    >{{ v }}</option>
                                </select>
                            </div>

                            <b-button @click="getContratFactureErreur(key)" variant="primary">
                                Rechercher contrat facture erreur
                            </b-button>

                            <b-button @click="getContratSansFacture(key)" variant="primary">
                                Rechercher contrat sans facture
                            </b-button>

                            <b-button @click="getFactureSansContrat(key)" variant="primary">
                                Rechercher facture sans contrat
                            </b-button>
                        </div>
                    </div>

                    <div v-if="contratfactureerreur !== null">
                        <LoadingSpinner v-if="spinner_table" class="col-12" />
                        <table class="table mt-3" v-else>
                            <tr>
                                <th>ID facture ligne</th>
                                <th>Label facture ligne</th>
                                <th>HT facture ligne</th>
                                <th>Quantité facture ligne</th>
                                <th>Cheval</th>
                                <th>Facture n°</th>
                                <th>Facture date</th>
                                <th>Contrat n°</th>
                                <th>Contrat n°</th>
                            </tr>
                            <tr 
                                v-for="(invoice) in contratfactureerreur"
                                :key="invoice.invoicedetails_id"
                            >
                                <td>{{ invoice.invoicedetails_id }}</td>
                                <td>{{ invoice.invoicedetails_label }}</td>
                                <td>{{ invoice.invoicedetails_ht }}</td>
                                <td>{{ invoice.invoicedetails_qte }}</td>
                                <td>{{ invoice.horse_nom }}</td>
                                <td>{{ invoice.invoice_num }}</td>
                                <td>{{ invoice.invoice_date }}</td>
                                <td>
                                    {{ invoice.contract_contract_num }}
                                    <b-button @click="saveContratFactureErreur(invoice, 'contract')" variant="primary" v-if="invoice.contract_contract_num">
                                        Choisir
                                    </b-button>
                                </td>
                                <td>
                                    {{ invoice.avenantarticles_contract_num }}
                                    <b-button @click="saveContratFactureErreur(invoice, 'avenantarticles')" variant="primary" v-if="invoice.avenantarticles_contract_num">
                                        Choisir
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div v-if="contratsansfacture !== null">
                        <LoadingSpinner v-if="spinner_table" class="col-12" />
                        <table class="table mt-3" v-else>
                            <tr>
                                <th>ID contrat</th>
                                <th>Label article</th>
                                <th>HT article</th>
                                <th>Compta article</th>
                                <th>Compte comptable article</th>
                                <th>Date avenant</th>
                                <th></th>
                            </tr>
                            <tr 
                                v-for="(contrat) in contratsansfacture"
                                :key="contrat.avenantarticles_id"
                            >
                                <td>{{ contrat.avenant_contract }}</td>
                                <td>{{ contrat.articles_label }}</td>
                                <td>{{ contrat.avenantarticles_ht }}</td>
                                <td>{{ contrat.accountingplan_label }}</td>
                                <td>{{ contrat.accountingaccount_label }} ({{ contrat.accountingaccount_number }})</td>
                                <td>{{ contrat.avenant_date }}</td>
                                <td>
                                    <table>
                                        <tr>
                                            <th>ID facture</th>
                                            <th>Num facture</th>
                                            <th>Date facture</th>
                                            <th>Label facture ligne</th>
                                            <th>HT facture ligne</th>
                                            <th></th>
                                        </tr>
                                        <tr
                                            v-for="(invoice) in contrat.lignes"
                                            :key="invoice.invoicedetails_id"
                                        >
                                            <td>{{ invoice.invoice_id }}</td>
                                            <td>{{ invoice.invoice_num }}</td>
                                            <td>{{ invoice.invoice_date }}</td>
                                            <td>{{ invoice.invoicedetails_label }}</td>
                                            <td>{{ invoice.invoicedetails_ht }}</td>
                                            <td>
                                                <b-button @click="saveContratSansFacture(contrat, invoice)" variant="primary">
                                                    Choisir cette facture ligne
                                                </b-button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div v-if="facturesanscontrat !== null">
                        <LoadingSpinner v-if="spinner_table" class="col-12" />
                        <table class="table mt-3" v-else>
                            <tr>
                                <th>ID facture</th>
                                <th>Num facture</th>
                                <th>Date facture</th>
                                <th>Label facture ligne</th>
                                <th>HT facture ligne</th>
                                <th>Compta article</th>
                                <th>Compte comptable article</th>
                                <th>Label article</th>
                                <th>HT article</th>
                                <th></th>
                            </tr>
                            <tr 
                                v-for="(invoice) in facturesanscontrat"
                                :key="invoice.invoicedetails_id"
                            >
                                <td>{{ invoice.invoice_id }}</td>
                                <td>{{ invoice.invoice_num }}</td>
                                <td>{{ invoice.invoice_date }}</td>
                                <td>{{ invoice.invoicedetails_label }}</td>
                                <td>{{ invoice.invoicedetails_ht }}</td>
                                <td>{{ invoice.accountingplan_label }}</td>
                                <td>{{ invoice.accountingaccount_label }} ({{ invoice.accountingaccount_number }})</td>
                                <td>{{ invoice.articles_label }}</td>
                                <td>{{ invoice.articles_ht }}</td>
                                <td>
                                    <table>
                                        <tr>
                                            <th>ID contrat</th>
                                            <th>Num contrat</th>
                                            <th>Date avenant</th>
                                            <th>HT avenant</th>
                                            <th></th>
                                        </tr>
                                        <tr
                                            v-for="(contrat) in invoice.lignes"
                                            :key="contrat.avenantarticles_id"
                                        >
                                            <td>{{ contrat.avenant_contract }}</td>
                                            <td>{{ contrat.contract_num }}</td>
                                            <td>{{ contrat.avenant_date }}</td>
                                            <td>{{ contrat.avenantarticles_ht }}</td>
                                            <td>
                                                <b-button @click="saveFactureSansContrat(invoice, contrat)" variant="primary">
                                                    Choisir cet avenant article
                                                </b-button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import User from "@/mixins/User.js"
import _debounce from 'lodash/debounce'

export default {
    name: 'RapprochementFacture',
    mixins: [User],
    data () {
        return {
            email: '',
            licences: [],
            spinner: false,
            spinner_table: false,
            contratfactureerreur: null,
            contratsansfacture: null,
            facturesanscontrat: null,
            annee_selected: null
        }
    },
    created() {
        this.debouncedSearch = _debounce(this.searchEmail, 1000)
    },
    methods: {
        async searchEmail() {
            this.contratfactureerreur = null
            this.contratsansfacture = null
            this.facturesanscontrat = null
            this.spinner_table = false

            this.getUserGamme(this.email).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                Object.keys(retour).forEach(licence_key => {
                    retour[licence_key].gamme = retour[licence_key].gamme[0]
                })

                this.licences = retour
            }).finally(() => {
                this.spinner = false
            })
        },
        async getContratFactureErreur(licence) {
            this.contratfactureerreur = []
            this.contratsansfacture = null
            this.facturesanscontrat = null
            this.spinner_table = true
            this.getRapprochementContratFactureErreur(licence, this.annee_selected).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                this.contratfactureerreur = retour
            }).finally(() => {
                this.spinner_table = false
            })
        },
        async saveContratFactureErreur(invoice, type) {
            this.saveRapprochementContratFactureErreur(invoice, type).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                if(retour.result) {
                    this.successToast('Ok')
                } else {
                    this.failureToast('Impossible de rapprocher le contrat avec la facture')
                }
            })
        },
        async getContratSansFacture(licence) {
            this.contratsansfacture = []
            this.contratfactureerreur = null
            this.facturesanscontrat = null
            this.spinner_table = true
            this.getRapprochementContratSansFacture(licence, this.annee_selected).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                this.contratsansfacture = retour
            }).finally(() => {
                this.spinner_table = false
            })
        },
        async saveContratSansFacture(contrat, invoice) {
            this.saveRapprochementContratSansFacture(contrat, invoice).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                if(retour.result) {
                    this.successToast('Ok')
                } else {
                    this.failureToast('Impossible de rapprocher le contrat avec la facture')
                }
            })
        },
        async getFactureSansContrat(licence) {
            this.facturesanscontrat = []
            this.contratfactureerreur = null
            this.contratsansfacture = null
            this.spinner_table = true
            this.getRapprochementFactureSansContrat(licence, this.annee_selected).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                this.facturesanscontrat = retour
            }).finally(() => {
                this.spinner_table = false
            })
        },
        async saveFactureSansContrat(invoice, contrat) {
            this.saveRapprochementFactureSansContrat(invoice, contrat).then(result => {
                let retour = result.retour || null
                if(!retour) throw 'No available result'

                if(retour.result) {
                    this.successToast('Ok')
                } else {
                    this.failureToast('Impossible de rapprocher la facture avec le contrat')
                }
            })
        }
    },
    computed: {
        annee: function() {
            let annee = []
            const date = new Date();
            for(let i = 2010; i <= (date.getFullYear()+1); i++) {
                annee.push(i)
            }

            return annee.reverse()
        }
    },
    watch: {
        'email' (val) {
            if(this.regex_email.test(String(val).toLowerCase())){
                this.spinner = true
                this.debouncedSearch()
            }
        },
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
    }
}
</script>